import validateParams from "./validateParams";
import validateRequestOptions from "../validateRequestOptions";
import validateGetAccessTokenDelegate from "../validateGetAccessTokenDelegate";
import { DEFAULT_LIST_ITEMS_URL_PATH } from "../constants";

/**
 * Update a product level saved item with a variant id
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {Object} params.item
 * @param {string} params.item.itemId saved item id to update
 * @param {integer} params.item.variantId variant id to update the saved item to
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise} Promise resolving to no content.
 * @throws {TypeError} itemId should be a valid guid.
 * @throws {TypeError} variantId should be a positive integer.
 * @throws {TypeError} configuration.{@link getAccessTokenDelegate|getAccessToken} is required, since this is an authorised endpoint.
 * @throws {GetAccessTokenError} error getting an access token from the getAccessToken delegate.
 * @throws {GetAccessTokenTimeoutError} timeout hit when attempting to get an access token from the getAccessToken delegate.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Modify a saved item|API Documentation}
 * @example
 * sdk.updateItem({ item: { itemId: "0e56bbf4-563a-4687-8647-8d87094049ad", variantId: 568790 } });
 */
const updateItem = (
  {
    api,
    context: { currency, keyStoreDataversion, lang, sizeSchema, store },
    configuration: { getAccessToken } = {}
  },
  { item: { itemId, variantId } = {}, requestOptions } = {}
) => {
  validateGetAccessTokenDelegate(getAccessToken);
  validateParams({ itemId, variantId });
  validateRequestOptions(requestOptions);
  return api.patch({
    path: `${DEFAULT_LIST_ITEMS_URL_PATH}/${itemId}`,
    body: { variantId },
    queryParams: { currency, keyStoreDataversion, lang, sizeSchema, store },
    requestOptions
  });
};

export default updateItem;
