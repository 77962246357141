/**
 * @memberof asos
 * @namespace customer
 */
/**
 * @memberof asos.customer
 * @namespace savedItemsSdk
 * @description
 * SDK for accessing the Saved Items {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html|API}.
 * Uses {@link asos.webRequest.configureRequest|asos-web-request.configureRequest} and {@link asos.webRequest.isCancelled|asos-web-request.isCancelled}, so requires that library to be on the page first.
 */
import { isCancelled } from "@asosteam/asos-web-request";
import sdk from "./sdk";
import { sorts, sortOrders, expands } from "./operations/constants";
import {
  GetAccessTokenError,
  GetAccessTokenTimeoutError
} from "./api/headers/authorisation/errors";
/**
 * function that can be used to check if a failed request was cancelled.
 * @function
 * @memberof asos.customer.savedItemsSdk
 * @returns {boolean} if the request was cancelled
 * @see {@link asos.webRequest.isCancelled|isCancelled}
 * @example
 * sdkInstance.getItems().then(() => {
 *   ...
 * }, reason => {
 *  const wasCancelled = sdk.isRequestCancelled(reason);
 *  ...
 * });
 */
const isRequestCancelled = isCancelled; // N.B. must be defined as a variable and not as a import alias, to allow ES module namespace outro to work with terser minification

export {
  sdk,
  isRequestCancelled,
  sorts,
  sortOrders,
  expands,
  GetAccessTokenError,
  GetAccessTokenTimeoutError
};
